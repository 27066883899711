<template>
  <div class="ticket">
    <!-- 第一部分 -->

    <Head :aircraftTicket="aircraftTicket[0]" />
    <PeoList :updateResult.sync="result" :seat="[coupons[chosenCoupon]]" :compliant="compliant" :explanationText="explanationText" @explanFinish="explanFinish" />

    <div class="gap"></div>
    <!-- 克隆的母体，选票的那个logo从这儿克隆 -->
    <Logo :corp="i" :id="i" style="display:none" v-for="i in allCorp" :key="i" />

    <!-- 第二部分 -->
    <van-panel title="选票">
      <van-coupon-cell class="chooseTicketBk" :title="coupons[chosenCoupon].seatClass+'（￥'+coupons[chosenCoupon].value+'）'" :value="'余票：'+(coupons[chosenCoupon].number>0?coupons[chosenCoupon].number:'充足')" @click="showList = true;checkClass()" />
      <van-collapse v-model="activeNames">
        <van-collapse-item title="退改规则" name="1">
          <div class="rule">
            <van-row gutter="10">
              <van-col class="thead" span="8">时间点</van-col>
              <van-col class="thead" span="5">退票费</van-col>
              <van-col class="thead" span="6">同舱改期费</van-col>
              <van-col class="thead" span="5">签转</van-col>
            </van-row>
            <van-row gutter="10" v-for="(item,i) in ruleList.rulePointList" :key="i">
              <van-col class="van-cell__label" span="8">{{item.content}}</van-col>
              <van-col class="van-cell__label" span="5">￥{{item.refundFee}}/人</van-col>
              <van-col class="van-cell__label" span="6">￥{{item.modifyFee}}/人</van-col>
              <van-col class="van-cell__label" span="5">{{item.modifyStipulate}}</van-col>
            </van-row>
          </div>
        </van-collapse-item>
      </van-collapse>
    </van-panel>
    <el-tooltip class="item" effect="dark" :content="ruleList.baggage" placement="top">
      <van-notice-bar left-icon="volume-o" :text="'托运规则：'+ruleList.baggage" />
    </el-tooltip>

    <!-- 第三部分 -->

    <!-- 座次类型弹出层 -->
    <van-popup v-model="showList" round position="bottom" class="pick-ticket">
      <van-coupon-list enabled-title="有票" disabled-title="无票" :coupons="coupons" :chosen-coupon="chosenCoupon" :disabled-coupons="disabledCoupons" :show-exchange-bar="false" :close-button-text="'确定'" @change="onChange" />
    </van-popup>

    <div class="gap"></div>

    <!-- 第三部分 -->
    <BodyList :totalPrice="totalPrice" />
    <div class="bottom-gap"></div>

    <!-- 第四部分 -->
    <van-submit-bar tip-icon="info-o" tip="实际支付价格以“确认出票”时所支付的价格为准。" id="price-bar" :price="totalPrice*100" button-text="提交占座" @submit="onSubmit"></van-submit-bar>

    <!-- 遮罩层 -->
    <van-overlay :show="wrapper" @click="show = false">
      <div class="wrapper" @click.stop>
        <LoadingAnimate :text="'占座中'" />
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { checkSeat } from "@/services/user";
import { createChangeOrder, updataTicketDom } from "@/services/aircraft";
import { AircraftServiceProvider,REASONS } from "@/common/config";
import Logo from "@/components/Logo";
import Head from "./components/InfoHead";
import PeoList from "./components/PeoList";
import BodyList from "@/components/InfoList";
import LoadingCircle from "@/components/LoadingCircle";
import LoadingAnimate from "@/components/LoadingAnimate";
import { getFlightModifyAndRefundRules, getFlightOrderRefundInfo } from "@/api/aircraft";

export default {
  components: { LoadingCircle, LoadingAnimate, Head, BodyList, PeoList, Logo },
  computed: {
    ...mapGetters([
      "aircraftTicket",
      "travelInfo",
      "aircraftOrderChange",
      "aircraftOriginPrice"
    ]),
    // 总票价
    totalPrice: function () {
      if (!this.refundFee) return null
      let price = this.coupons[this.chosenCoupon].value * this.result.length - this.aircraftOriginPrice
      price = this.refundFee + (price > 0 ? price : 0)
      return price;
    }
  },
  created() {
  },
  activated() {
    window.setTitle(this.aircraftTicket[0].flightNo);
    this.getSeatList();
    this.fetchRule();
    this.compliant = false
    this.wrapper = false
    this.showList = false
    getFlightOrderRefundInfo({
      orderNo: this.aircraftOrderChange.order.orderSerialNo || this.order.orderNo,
      corp: this.aircraftOrderChange.order.corp,
      FlightPassengers: this.result
    })
      .then(response => {
        document.getElementById('price-bar').querySelector('.van-submit-bar__text').children[0].innerHTML = '预计：'
        this.refundFee = response.data[0].refundFee
      })
      .catch(err => {
        this.refundFee = 100
      });
  },
  data() {
    return {
      allCorp: AircraftServiceProvider,
      refundFee: null, // 退票费
      showPicker: false,
      result: [], // 已选择的人员列表
      chosenCoupon: 0, // 可购列表选中的下标
      coupons: [], // 可购座位列表
      disabledCoupons: [], // 不可购列表
      ruleList: {}, // 退改签规则
      showList: false, // 选座弹出层
      compliant: false, // 超标填表开关
      wrapper: false, // 遮罩层
      explanation: REASONS[0], // 申请信息
      explanation1: "",
      explanationText: [],
      index: null, // 编辑框位置
      activeNames: [], // 折叠面板
      show: false,
      checked: true
    };
  },
  methods: {
    // 超额填表成功
    explanFinish(explanation, explanation1) {
      this.compliant = false
      if (explanation && explanation1) {
        this.explanation = explanation
        this.explanation1 = explanation1
        this.grabSeat()
      }
    },
    // 生成两种座位列表
    getSeatList() {
      this.coupons = [];
      this.disabledCoupons = [];
      let minpeo = { stand: { allowBookHighest: 999999 } };
      this.aircraftOrderChange.peoList.forEach(e => {
        if (parseInt(e.stand.allowBookHighest) < minpeo.stand.allowBookHighest)
          minpeo = e;
      });
      this.aircraftTicket[0].flightSeat.forEach(element => {
        element.description = checkSeat(minpeo, element, "aircraft")
          ? "无超标情况"
          : "有超标情况";
        element.condition = element.discount || " ";
        element.corp = this.aircraftOrderChange.order.corp;
        element.reason = "";
        element.name =
          element.seatClass +
          (element.number != 0 ? " | 余票：" + (element.number > 0 ? element.number : '充足') : "");
        element.value = element.price.seatPrice;
        element.valueDesc = element.price.seatPrice;
        element.unitDesc = "元";
        element.number != 0
          ? this.coupons.push(element)
          : this.disabledCoupons.push(element);
      });
    },
    // 选票的钩子
    onChange(index) {
      this.showList = false;
      this.chosenCoupon = index >= 0 ? index : this.chosenCoupon;
      this.fetchRule();
    },
    // 拉取退改签规则
    fetchRule() {
      getFlightModifyAndRefundRules({
        bookInfo: this.coupons[this.chosenCoupon].bookInfoStr,
        corp: this.aircraftTicket[0].corp
      }).then(response => {
        this.ruleList = response.data;
      });
    },
    // 点击提交按钮，该方法仅做一些简单的验证，之后还需要调用合规检查，最后才可以提交占座
    onSubmit: async function () {
      this.explanationText = [];
      if (this.result.length <= 0) return this.$toast.fail("尚未选择人员");
      // 行程单余额控制
      // let checkPrice = await this.$store.dispatch("project/updateAppPrice", this.totalPrice);
      // if (this.travelInfo.isControl=="yes"&&!checkPrice) return this.$toast.fail("行程限额不足");
      // 人员信息检查
      for (let i = 0; i < this.result.length; i++) {
        let element = this.result[i];
        if (
          !element.hasOwnProperty("tel") ||
          !element.hasOwnProperty("birthday") ||
          !element.hasOwnProperty("gender")
        )
          return this.$toast.fail("人员信息不完整");
      }
      this.result.forEach(element => {
        if (!checkSeat(element, this.coupons[this.chosenCoupon], "aircraft")) {
          this.explanationText.push(element);
        }
      });
      if (this.explanationText.length > 0) return (this.compliant = true);
      this.grabSeat();
    },
    // 抢座
    async grabSeat() {
      this.wrapper = true;
      let req = {
        flightInfo: this.aircraftTicket[0],
        applyNo: this.travelInfo.appId,
        orderNo: this.aircraftOrderChange.order.orderSerialNo,
        flightPassengerItem: this.result,
        corp: this.aircraftOrderChange.order.corp
      };
      let ticket1 = await createChangeOrder(req, this.$route.name);
      this.wrapper = false;
      if (ticket1) {
        this.$router.push({
          path: "/plan",
          // 下方前往详情（弃用，功能保留）
          // path: "/paymentAircraftChange",
          // query: {
          //   corp: ticket1.corp,
          //   orderNo: ticket1.orderSerialNo,
          //   outOrderNo: ticket1.outOrderNo
          // }
        });
      }
    },
    // 操作dom 超标票变红
    checkClass() {
      updataTicketDom(this.coupons)
    }
  },
};
</script>

<style lang="less" src="@/styles/orderCommon.less"></style>
<style lang="less">
.rule {
  .van-panel__header {
    padding: 5px 16px;
  }
}
.wrapper .wrapper {
  top: 74% !important;
}
</style>
<style lang="less" scoped>
.rule {
  padding: 0 16px;
  padding-bottom: 10px;
  .thead {
    font-size: 14px;
    color: #444;
  }
}
.search {
  font-size: 17px;
}
.content {
  padding: 16px 16px 90px;
}

.text {
  transition: all 2s;
  font-size: 14px;
  color: rgb(92, 92, 92);
  width: 97%;
}
.product {
  color: #1989fa;
  font-size: 16px;
}
.bigcheck {
  position: relative;
  top: -10px;
}
.fanxian {
  font-size: 12px;
  color: #a5a5a5;
}
.tuigai {
  width: 100%;
}
.custom-title {
  padding-right: 4px;
}
</style>
